import React from 'react'
import { Carousel } from 'react-carousel-minimal';
import _ from 'lodash';

export default class ImageCarousel extends React.Component {
    // Example usage
    // <image-carousel>
    //     <span data-src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/0c/GoldenGateBridge-001.jpg/1200px-GoldenGateBridge-001.jpg" data-caption="Awesome caption :)"></span>
    //     <span data-src="https://cdn.britannica.com/s:800x450,c:crop/35/204435-138-2F2B745A/Time-lapse-hyper-lapse-Isle-Skye-Scotland.jpg"></span>
    // </image-carousel>

    render() {
        //TODO: Some one please tell me there's a better way to get images dynamically from the markdown document :cry:
        //      This just uses "fake" spans to pass the data
        let carouselImages = [];
        _.each(this.props.children, child => {
            if (_.isObjectLike(child) && _.has(child, 'props.data-src')) {
                let imageObj = {
                    image: child.props["data-src"]
                };
                if (_.has(child, 'props.data-caption')) {
                    imageObj.caption = child.props["data-caption"];
                }
                carouselImages.push(imageObj);
            }
        });

        const captionStyle = {
            fontSize: '2em',
            fontWeight: 'bold',
            textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000'
        }
        const slideNumberStyle = {
            fontSize: '20px',
            fontWeight: 'bold',
        }
        return (
            <div>
                <Carousel
                    data={carouselImages}
                    time={7500}
                    width="100%"
                    height="500px"
                    captionStyle={captionStyle}
                    radius="10px"
                    slideNumber={true}
                    slideNumberStyle={slideNumberStyle}
                    captionPosition="bottom"
                    automatic={true}
                    dots={true}
                    pauseIconColor="white"
                    pauseIconSize="40px"
                    slideBackgroundColor="darkgrey"
                    slideImageFit="cover"
                    thumbnails={true}
                    thumbnailWidth="100px"
                    style={{
                        textAlign: "center",
                        maxHeight: "500px",
                        margin: "40px auto",
                        marginBottom: "100px"
                    }}
                />
            </div>
        )
    }
}